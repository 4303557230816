import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0,3,4,5];

export const dictionary = {
		"/": [6],
		"/(marketing)/about": [7,[2]],
		"/admin/agency/create": [~15,[3]],
		"/admin/agency/list": [~16,[3]],
		"/admin/agency/[id]/agent/create": [~13,[3]],
		"/admin/agency/[id]/agent/[agid]/transfer": [~12,[3]],
		"/admin/agency/[id]/edit": [~14,[3]],
		"/admin/campaign/list": [~18,[3]],
		"/admin/campaign/[id]": [~17,[3]],
		"/admin/dashboard": [~19,[3]],
		"/admin/investment/edit": [~20,[3]],
		"/admin/investment/list": [~21,[3]],
		"/admin/mail/template/list": [~23,[3]],
		"/admin/mail/template/[id]": [~22,[3]],
		"/admin/member/list": [~25,[3]],
		"/admin/member/[id]": [~24,[3]],
		"/admin/settings": [~26,[3]],
		"/admin/settings/test": [~27,[3]],
		"/auth/initiation": [~28,[4]],
		"/auth/initiation/password": [~29,[4]],
		"/auth/initiation/thanks": [~30,[4]],
		"/auth/password": [~31,[4]],
		"/auth/password/sent": [32,[4]],
		"/auth/signin": [~33,[4]],
		"/auth/signout": [34,[4]],
		"/(marketing)/contact": [8,[2]],
		"/(marketing)/faq": [9,[2]],
		"/(marketing)/legal": [10,[2]],
		"/member/agency/agent/create": [~36,[5]],
		"/member/agency/agent/list": [~37,[5]],
		"/member/agency/agent/[agid]": [~35,[5]],
		"/member/dashboard": [~38,[5]],
		"/member/investment/create": [~40,[5]],
		"/member/investment/history": [~41,[5]],
		"/member/investment/offering": [~42,[5]],
		"/member/investment/search": [~43,[5]],
		"/member/investment/[id]": [~39,[5]],
		"/member/settings": [~44,[5]],
		"/member/settings/password": [~45,[5]],
		"/member/unsubscribe": [~46,[5]],
		"/(marketing)/search": [~11,[2]],
		"/sentry-example": [47]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';